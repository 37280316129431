import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "@/router/app";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
//import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";

import { useAuth } from "vue-plugin-msal";

const pinia = createPinia();

const msalConfig = {
  auth: {
    clientId: "c420fe7c-6fb8-46bb-9e07-0b1213faf962",
    authority:
      "https://login.microsoftonline.com/1c7f1e86-93cd-40a2-8732-662400d3667f/",
    redirectUri: process.env.VUE_APP_REDIRECTURI,
    postLogoutRedirectUri: process.env.VUE_APP_POSTLOGOUT_REDIRECTURI,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

const axiosConfig = {
  baseURL: "https://saftladen-api.azurewebsites.net/api/",
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
};

const auth = useAuth(pinia).init(msalConfig, axiosConfig).registerGuard(router);

const app = createApp(App);
app.use(pinia);
app.use(store);
app.use(router);
app.use(ElementPlus);

//ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();
app.use(i18n);

app.mount("#app");
